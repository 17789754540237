import {Controller as BaseController} from "@hotwired/stimulus"
// @ts-ignore
import {FetchRequest} from "@rails/request.js";
import QRCode from 'qrcode';
import {useIntersection, useVisibility} from "stimulus-use";

export default class extends BaseController<HTMLElement> {
  static values = {
    url: String,
    payload: String,
    refreshInterval: Number
  }
  static targets = ['canvas']

  declare readonly urlValue: string
  declare readonly payloadValue: string
  declare readonly refreshIntervalValue?: number
  declare readonly canvasTarget: HTMLElement
  declare timer: ReturnType<typeof setInterval>
  declare isIntersecting: boolean
  declare isVisible: boolean

  connect() {
    useVisibility(this)
    useIntersection(this)

    this.update()
  }

  appear() {
    this.isIntersecting = true
    this.update()
  }

  disappear() {
    this.isIntersecting = false
    this.update()
  }

  visible() {
    this.isVisible = true
    this.update()
  }

  invisible() {
    this.isVisible = false
    this.update()
  }

  shouldRefresh() {
    return this.isVisible && this.isIntersecting
  }

  isStatic() {
    return !!this.payloadValue
  }

  update() {
    if (this.isStatic() && this.shouldRefresh()) {
      this.renderQRCode(this.payloadValue)
      return;
    }

    if (this.shouldRefresh()) {
      this.startTimer()
    } else {
      this.stopTimer()
    }
  }

  async renderQRCode(value: string) {
    this.canvasTarget.innerHTML = await QRCode.toString(value, {margin: 0, width: 512, color: {light: '#0000'}})
  }

  startTimer() {
    if (this.timer) {
      this.stopTimer()
    }

    this.timer = setInterval(this.refresh.bind(this), this.refreshIntervalValue || 3000)
    this.refresh()
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  async refresh() {
    const request = await new FetchRequest('get', this.urlValue);
    const response = await request.perform();

    if (!response.ok) {
      console.error('Failed to fetch value', response.statusCode);
      return;
    }

    const value = await response.text

    this.renderQRCode(value)
  }
}
