import {Controller as BaseController} from "@hotwired/stimulus"

export default class extends BaseController<HTMLElement> {
  static targets = ["mobileMenu"]

  declare readonly mobileMenuTarget: HTMLElement

  toggle() {
    this.mobileMenuTarget.classList.toggle("hidden")
  }
}
