import {Controller as BaseController} from "@hotwired/stimulus"
import {useClickOutside} from "stimulus-use";

export default class extends BaseController<HTMLElement> {
  static targets = ["dialog", "content"]
  static values = {show: Boolean}

  declare readonly contentTarget: HTMLElement
  declare readonly dialogTarget: HTMLDialogElement
  declare readonly showValue: boolean

  connect() {
    useClickOutside(this, {element: this.contentTarget, onlyVisible: true})

    if (this.showValue) {
      console.log("show modal")
      this.open()
    }
  }

  open() {
    document.body.classList.add("modal-open")
    this.dialogTarget.showModal()
  }

  close(event: Event) {
    this.dialogTarget.close()
    document.body.classList.remove("modal-open")
  }

  clickOutside(event: Event) {
    event.preventDefault()

    this.close(event);
  }
}
