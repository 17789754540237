import {Controller as BaseController} from "@hotwired/stimulus"

type IJustUpdatedEvent = 'create' | 'update'

export default class extends BaseController<HTMLElement> {
  static values = {
    justUpdated: Boolean,
    justUpdatedEvent: String
  }

  declare readonly justUpdatedValue: boolean
  declare readonly justUpdatedEventValue: IJustUpdatedEvent

  connect() {
    if (this.justUpdatedValue) {
      setTimeout(() => {
        switch (this.justUpdatedEventValue) {
          case "create":
            this.element.classList.remove('max-h-0')
            this.element.classList.add('max-h-64')
            break;
          case "update":
            this.element.classList.add('animate-pulse')
            break;
        }
      }, 1);
    }
  }
}
